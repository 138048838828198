import React from 'react';

interface Numbers {
  primary: number[];
  secondary: number[];
}

function number(n: number) {
  return (
    <div className="w-12 text-center">
      {n}
    </div>
  )
}

function App() {
  const [numbers, setNumbers] = React.useState<Numbers>({
    primary: [ ],
    secondary: [ ],
  });

  // Generate Numbers
  const generateNumbers = (limitPrimary: number, primaryCount: number, limitSecondary: number, secondaryCount: number) => {
    const primary: number[] = [ ];
    while (primary.length < primaryCount) {
      const n = Math.floor(Math.random() * limitPrimary) + 1;
      if (!primary.includes(n)) {
        primary.push(n);
      }
    }

    const secondary: number[] = [ ];
    while (secondary.length < secondaryCount) {
      const n = Math.floor(Math.random() * limitSecondary) + 1;
      if (!secondary.includes(n)) {
        secondary.push(n);
      }
    }

    setNumbers({
      primary,
      secondary,
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="flex flex-col space-y-4">
        <div className="flex flex-row space-x-4 text-xl">
          <div className="card bg-base-300">
            <div className="card-body">
              {numbers.primary.sort((a, b) => a - b).map(number)}
            </div>
          </div>
          <div className="card bg-base-300">
            <div className="card-body">
              {numbers.secondary.sort((a, b) => a - b).map(number)}
            </div>
          </div>
        </div>
        <div className="self-end flex flex-col space-y-4">
          <button className="btn btn-primary" onClick={() => generateNumbers(50, 5, 12, 2)}>
            Neue zahlen 50
          </button>
          <button className="btn btn-primary" onClick={() => generateNumbers(49, 6, 0, 0)}>
            Neue zahlen 49
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
